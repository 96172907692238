import React, {useEffect, useState} from 'react'
import {Form, Input, Button, Modal} from 'antd'
import common from "../../utils/common"
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'
import {LockOutlined, MobileOutlined, MailOutlined} from '@ant-design/icons';
import './login.less'
import Wechat from "./wechat";

function Login(props) {
    let {scanKey, setShow, setScanKey, redirect} = props

    const [form] = Form.useForm();

    let [mobileValue, setMobileValue] = useState('')
    let [verificationKey, setVerificationKey] = useState('')
    let [verificationCode, setVerificationCode] = useState('')
    let [imgCaptchaUrl, setImgCaptchaUrl] = useState('')
    let [imgCaptchaKey, setImgCaptchaKey] = useState('')
    let [imgCaptchaCode, setImgCaptchaCode] = useState('')

    // 用于在右上角点注册或登录按扭时，直接显示指定的界面
    // 登录 /login?type=1
    // 注册 /login?type=2
    const searchParams = new URLSearchParams(props.location.search.substring(1))
    let type = searchParams.get("type")

    // 登录方式有三种：qrcode、sms、password
    let [loginType, setLoginType] = useState("password")

    useEffect(() => {

        if (type === '2') {
            // 类型为2是注册，如果没有启用扫码，则显示短信登录界面
            if (!isEnableQrcodeLogin()) {
                setLoginType('sms')
                return
            }
        }

        // 设置默认登录类型
        setLoginType(getDefaultLoginType())

    }, [])

    // 发送验证码
    let sendSms = () => {

        if (mobileValue.length !== 11) {
            common.toast("请输入 11 位手机号码")
            return
        }

        setImgCaptchaUrl('')

        let mobile = mobileValue
        let captchaKey = imgCaptchaKey
        let captchaCode = imgCaptchaCode

        common.loadingStart()
        common.ajax("post", "/api/user/sms", {
            mobile, captchaKey, captchaCode,
            tenant_id: props.tenant.id
        }, {useToken: false, displayError: false, contentType: 'application/x-www-form-urlencoded'}).then((data) => {
            setVerificationKey(data.verificationKey)
            common.toast(data.message)

        }).catch((res) => {
            if (res.code === "INVALID_CAPTCHA") {
                common.ajax("get", '/api/image/captcha', {}, {useToken: false}).then((result) => {
                    setImgCaptchaKey(result.key)
                    setImgCaptchaUrl(result.url)
                })

            } else {
                common.alert(res.data)
            }

        }).finally(common.loadingStop)
    }

    // 点击注册
    let handleSubmit = () => {

        if (mobileValue.length === 0) {
            common.toast("请输入手机号码")
            return;
        }

        if (verificationKey.length === 0) {
            common.toast("请获取验证码")
            return
        }

        if (verificationCode.length === 0) {
            common.toast("请输入短信验证码")
            return
        }

        let inviteCode = window.localStorage.getItem('invite')

        let params = {
            mobile: mobileValue,
            verificationKey: verificationKey,
            verificationCode: verificationCode,
            scanKey: scanKey,
            inviteCode: inviteCode ? inviteCode : '',
            tenant_id: props.tenant.id,
            device_code: common.deviceCode(),
            device_info: JSON.stringify(common.deviceInfo()),
        };

        common.loadingStart()
        common.ajax("post", "/api/user/registerV2", params, {
            useToken: false,
            contentType: 'application/x-www-form-urlencoded'
        }).then((data) => {

            // 老用户登录成功，返回token
            if (data.token) {

                //保存Token
                common.setToken(data.token, data.refresh_token)

                //拉取当前用户的基础信息
                userInfo()
            } else {
                // 新用户，去完善个人信息
                window.localStorage.setItem("accessToken", data.accessToken)
                setShow('info')
            }


        }).finally(() => {
            common.loadingStop()
        })
    }


    // 拉取当前用户的基础信息
    let userInfo = () => {
        //拉取当前用户的基础信息
        common.ajax("get", "/api/user/whoami", {}, {
            useToken: true,
            contentType: 'application/x-www-form-urlencoded'
        }).then((data) => {

            let user = {
                id: data.id,
                nickname: data.nickname,
                avatar: data.avatar,
                avatar_url: data.avatar_url,
            }

            props.changeUser(user)
            redirect()
        })
    }

    // 密码登录
    function onFinish(values) {

        let params = {
            account: values.account,
            password: values.password,
            scanKey: scanKey,
            tenant_id: props.tenant.id,
            device_code: common.deviceCode(),
            device_info: JSON.stringify(common.deviceInfo()),
        }

        common.loadingStart()

        common.ajax("post", "/api/user/token", params, {
            useToken: false,
            contentType: 'application/x-www-form-urlencoded'
        }).then((data) => {
            //保存Token
            common.setToken(data.token, data.refresh_token)

            //拉取当前用户的基础信息
            getUserInfo()

        }).finally(() => {
            common.loadingStop()
        })

    }

    //拉取当前用户的基础信息
    let getUserInfo = () => {
        //拉取当前用户的基础信息
        common.ajax("get", "/api/user/whoami", {}, {
            useToken: true,
            contentType: 'application/x-www-form-urlencoded'
        }).then((data) => {

            let user = {
                id: data.id,
                nickname: data.nickname,
                avatar: data.avatar,
                avatar_url: data.avatar_url,
            }

            props.changeUser(user)

            common.message.success("登录成功")

            redirect()
        })
    }

    const isEnableQrcodeLogin = () => {
        if (common.isEnableWechatLogin(props.tenant) && !common.isMobile()) {
            return true
        } else {
            return false
        }
    }

    const getDefaultLoginType = () => {
        if (isEnableQrcodeLogin()) {
            return 'qrcode'
        } else {
            return 'password'
        }
    }

    // 密码登录
    let loginWithPassword = <>
        <div className="title">
            请登录
        </div>
        <Form form={form} onFinish={onFinish} className="login-form">
            <Form.Item name="account" rules={[{required: true, message: '请输入手机号/账号'}]}>

                <Input
                    className={'txt'}
                    prefix={<MobileOutlined style={{color: 'rgba(0,0,0,.5)'}}/>}
                    placeholder="手机号/账号"
                />

            </Form.Item>
            <Form.Item name="password" rules={[{required: true, message: '请输入密码'}]}>

                <Input
                    className={'txt'}
                    prefix={<LockOutlined style={{color: 'rgba(0,0,0,.5)'}}/>}
                    type="password"
                    placeholder="密码"
                />

            </Form.Item>
            <Form.Item>
                <Button
                    type="primary" htmlType="submit" className="login-form-button">
                    登录
                </Button>
                <div style={{'display': 'flex', marginTop: 20, flexDirection: 'row', justifyContent: 'space-around'}}>
                    <Button className={'btn'} type='link' onClick={() => {

                        if (isEnableQrcodeLogin()) {
                            setLoginType("qrcode")
                        } else {
                            setLoginType('sms')
                        }

                    }}>没有帐号</Button>

                    <Button className={'btn'} type='link' onClick={() => {
                        setShow('forget')
                    }}>忘记密码</Button>
                </div>

                {isEnableQrcodeLogin() ?
                    <div style={{
                        cursor: 'pointer',
                        marginTop: 20,
                        textAlign: "center",
                        borderTop: "1px solid #ccc",
                        paddingTop: 20
                    }}>
                        <div onClick={() => {
                            setLoginType('qrcode')
                        }}>
                            <img width="30" src={require('../../img/wechat.png')} alt=""/>
                            <div style={{color: '#666'}}>微信登录</div>
                        </div>
                    </div> : ''}

            </Form.Item>
        </Form>
    </>

    // 微信未绑定账号
    let weChatBind = <div className="weChat-bind">
        <div className="title we" style={{lineHeight: 1.5}}>
            手机验证码登录
        </div>
        <div style={{textAlign: "center", color: "#aaa", margin: 20, fontSize: 16}}>
            请点击获取验证码
        </div>

        <div>
            <Input maxLength={11} size="large" addonBefore="+86"
                   placeholder={"输入手机号码"} onChange={e => {
                setMobileValue(e.target.value)
            }}/>
        </div>

        <div style={{display: "flex", marginTop: 30, gap: 20}}>
            <Input size="large" style={{fontWeight: "bold"}}
                   addonBefore={<MailOutlined style={{padding: "0 5px"}}/>}
                   placeholder={"输入短信验证码"} onChange={e => {
                setVerificationCode(e.target.value)
            }}/>
            <Button className={"sendSms"} type={"default"} size={"large"}
                    onClick={sendSms}>获取验证码</Button>
        </div>

        <Button className="button-list" block type={'primary'}
                style={{marginTop: 40}}
                onClick={handleSubmit}>确定</Button>

        <div className="remark-font2">如果您是新用户，验证成功后将为您注册。</div>
        <div className="remark-font2">我有帐号，<a onClick={() => {

            setLoginType(getDefaultLoginType())

        }}>使用密码登录</a></div>

    </div>


    return (
        <div className="Login">


            {loginType === 'password' ?
                loginWithPassword
                : ''}


            {loginType === 'sms' ?
                weChatBind
                : ''}

            {loginType === 'qrcode' ?
                <>
                    <Wechat getUserInfo={getUserInfo} setScanKey={(key) => {
                        setScanKey(key)
                        setLoginType('sms')
                    }}
                    />
                    <div className="login-type">
                        <Button className={'change'} type='link' onClick={() => {
                            setLoginType('password')
                        }}>使用密码登录</Button>
                    </div>
                </>

                : ''}

            <Modal
                width={420}
                open={imgCaptchaUrl}
                footer={null}
                maskClosable={false}
                onCancel={() => {
                    setImgCaptchaUrl('')
                }}
            >
                <div>
                    <img src={imgCaptchaUrl} alt=""/>

                    <Form>
                        <Form.Item
                            label={
                                <span>请输入图片中的数字</span>
                            }
                        >
                            <Input size={"large"} placeholder={""} onChange={(e) => {
                                setImgCaptchaCode(e.target.value)
                            }}/>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" size={"large"} onClick={sendSms}>确定</Button>
                        </Form.Item>
                    </Form>


                </div>
            </Modal>
        </div>
    )
}

const LoginForm = Login

const mapStateToProps = (state) => {
    return {
        tenant: state.tenant,
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm))

