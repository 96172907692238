import React, {useState} from 'react';
import {
    Form,
    Input,
    Tooltip,
    Cascader,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
    AutoComplete, Modal,
} from 'antd';
import common from "../../utils/common";
import {CheckCircleOutlined} from '@ant-design/icons';
import './forget.less'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const {Option} = Select;

function Forget(props) {
    let [mobileValue, setMobileValue] = useState('')  // 输入的手机号
    let [password, setPassword] = useState('')        // 输入密码
    let [confirm, setConfirm] = useState('')          // 输入重复密码

    let [verificationKey, setVerificationKey] = useState('')
    let [confirmDirty, setConfirmDirty] = useState(false)
    let [imgCaptchaUrl, setImgCaptchaUrl] = useState('')
    let [imgCaptchaKey, setImgCaptchaKey] = useState('')
    let [imgCaptchaCode, setImgCaptchaCode] = useState('')
    const [form] = Form.useForm();

    // 点击确定
    let handleSubmit = (values) => {

        let params = {
            mobile: values.mobile,
            verificationKey: verificationKey,
            verificationCode: values.captcha,
            password: values.password,
            rePassword: values.confirm,
        };

        common.loadingStart()

        common.ajax("post", "/api/user/forget", {
            'data': JSON.stringify(params)
        }, {useToken: false, contentType: 'application/x-www-form-urlencoded'}).then((data) => {

            common.message.success("重置密码成功，请登录")

            props.setShow('login')

        }).finally(() => {
            common.loadingStop()
        })
    }

    // 验证码
    let sendSms = (e) => {
        setImgCaptchaUrl('')

        let mobile = mobileValue
        let captchaKey = imgCaptchaKey;
        let captchaCode = imgCaptchaCode;

        common.loadingStart()
        common.ajax("post", "/api/user/sms", {
            mobile, captchaKey, captchaCode,
            tenant_id: props.tenant.id
        }, {useToken: false, displayError: false, contentType: 'application/x-www-form-urlencoded'}).then((data) => {

            setVerificationKey(data.verificationKey)
            common.toast(data.message)

        }).catch((res) => {
            if (res.code === "INVALID_CAPTCHA") {
                common.ajax("get", '/api/image/captcha', {}, {useToken: false}).then((result) => {
                    setImgCaptchaKey(result.key)
                    setImgCaptchaUrl(result.url)
                })

            } else {
                common.alert(res.data)
            }

        }).finally(common.loadingStop)

    }

    // 输入新密码时触发的
    let validateToNextPassword = (rule, value, callback) => {
        const {form} = props;
        if (value && confirmDirty) {
            // form.validateFields(['confirm'], {force: true});
        }
        callback();
    };

    // 输入确认密码时触发的
    let compareToFirstPassword = (rule, value, callback) => {
        const {form} = props;
        if (value && value !== password) {
            callback('两次密码不一致');
        } else {
            callback();
        }
    };

    // 确认密码失去焦点
    let handleConfirmBlur = e => {
        const {value} = e.target;
        setConfirmDirty(confirmDirty || !!value)
    };

    let {setShow} = props

    // 表单样式
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };

    // 验证码弹框样式
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    // 手机号+86
    const prefixSelector =
        <Select style={{width: 70}} name='prefix' defaultValue={'86'}>
            <Option value="86">+86</Option>
        </Select>;

    return (
        <div className="Forget">
            <div className={'myform'}>
                <div className="title">重置密码</div>
                <Form {...formItemLayout} form={form} onFinish={handleSubmit}
                      className="login-form">
                    <Form.Item label="手机号" name="mobile"
                               rules={[{required: true, message: '请输入手机号'}]}>
                        <Input style={{width: '100%'}}
                               className={'txt'}
                               placeholder={'请输入手机号'}
                               onChange={(e) => {
                                   setMobileValue(e.target.value)
                               }}/>
                    </Form.Item>

                    <Form.Item label="验证码" name="captcha"
                               rules={[{required: true, message: '请输入验证码'}]}>
                        <Row gutter={8}>
                            <Col span={16}>
                                <Input
                                    className={'txt'}
                                    placeholder={'短信验证码'}/>
                            </Col>
                            <Col span={8}>
                                <Button type={'primary'} className={'send'} onClick={sendSms}>发送</Button>
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item label="新密码" name="password"
                               rules={[
                                   {required: true, message: '请输入新密码',},
                                   {validator: validateToNextPassword,},
                               ]}>
                        <Input.Password
                            className={'txt'}
                            placeholder={'请输入新密码'} onChange={(e) => {
                            setPassword(e.target.value)
                        }}/>
                    </Form.Item>

                    <Form.Item label="重复密码" name="confirm"
                               rules={[
                                   {required: true, message: '请确认密码'},
                                   {validator: compareToFirstPassword},
                               ]}
                               hasFeedback>
                        <Input.Password
                            className={'txt'}
                            placeholder={'再输一次新密码'} onBlur={handleConfirmBlur}
                            onChange={(e => {
                                setConfirm(e.target.value)
                            })}/>
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            确认
                        </Button>
                        <Button type="link" onClick={() => {
                            setShow('login')
                        }}>返回登录</Button>
                        <Button type="link" onClick={() => {
                            setShow('login')
                        }}>去注册</Button>
                    </Form.Item>
                </Form>

                <Modal
                    open={imgCaptchaUrl}
                    footer={null}
                    onCancel={() => {
                        setImgCaptchaUrl('')
                    }}
                >
                    <div>
                        <img src={imgCaptchaUrl} alt=""/>

                        <Form>

                            <Form.Item
                                label={
                                    <span>
                          请输入图片中的数字
                            </span>
                                }
                            >
                                <Input placeholder={""} onChange={(e) => {
                                    setImgCaptchaCode(e.target.value)
                                }}/>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" onClick={sendSms}>确定</Button>
                            </Form.Item>
                        </Form>


                    </div>
                </Modal>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        tenant: state.tenant,
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forget))

